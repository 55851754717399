.logo-container {
  z-index: -1;
  width: 400px;
  height: 609px;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  &::before {
    content: '<svg>';
    font-family: 'La Belle Aurore';
    color: #ffd700;
    font-size: 18px;
    position: absolute;
    margin-top: -40px;
    left: 15px;
    opacity: 0.6;
  }

  &::after {
    content: '</svg>';
    font-family: 'La Belle Aurore';
    color: #ffd700;
    font-size: 18px;
    position: absolute;
    margin-top: 550px;
    margin-left: -40px;
    animation: fadeIn 1s 1.7s backwards;
    opacity: 0.6;
  }

  svg{
    width: 100%;
    height: auto;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.frame-logo {
  stroke: #022c43;
  stroke-width: 0.9;
  fill: none;
  stroke-dasharray: 938.3937377929688;
  stroke-dashoffset: -938.3937377929688;
  width: 100%;
  animation: move 6s ease-out 1s forwards;
  @keyframes move {
    100% {
      stroke-dashoffset: 0;
      stroke: #ffd700;
      opacity: 1;
    }
  }
}
